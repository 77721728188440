import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from './ChatWidget';
import { FormState } from '../../utils/chatbot/types';

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

export const renderOptionalFields = (
  requestType: string,
  secondRequestType: string,
  handleChangeForm: (evt: ChangeEvent<HTMLInputElement>) => void,
  formState: FormState,
  errors: { [key: string]: string }
) => {
  if (secondRequestType === 'demande_de_multipoint_point_de_livraison_sur_produit_gamme_catalogue__n°_de_devis_obligatoire_') {
    return (
      <>
        <Input type="number" name="quote_number" placeholder="Numéro de devis" value={formState.quote_number} onChange={handleChangeForm} required />
        {errors.quote_number && <ErrorMessage>{errors.quote_number}</ErrorMessage>}
        <Input
          type="number"
          name="delivery_points_number"
          placeholder="Nombre de points de livraison"
          value={formState.delivery_points_number}
          onChange={handleChangeForm}
          required
        />
        {errors.delivery_points_number && <ErrorMessage>{errors.delivery_points_number}</ErrorMessage>}
      </>
    );
  }

  if (requestType === 'informations_sur_une_commande_en_cours_' || requestType === 'information_sur_votre_réclamation') {
    return (
      <>
        <Input type="number" name="order_number" placeholder="Numéro de commande" value={formState.order_number} onChange={handleChangeForm} required />
        {errors.order_number && <ErrorMessage>{errors.order_number}</ErrorMessage>}
      </>
    );
  }

  if (requestType === 'informations_sur_mon_compte') {
    return (
      <>
        <Input type="text" name="account_number" placeholder="Numéro de compte" value={formState.account_number || ''} onChange={handleChangeForm} required />
        {errors.account_number && <ErrorMessage>{errors.account_number}</ErrorMessage>}
      </>
    );
  }

  return null;
};

export default renderOptionalFields;
