export function setWithExpiry(key: string, value: unknown, ttl: number) {
  const now = new Date();

  const item = {
    value: JSON.stringify(value),
    expiry: now.getTime() + ttl
  };

  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(item));
  }
}

export function getWithExpiry(key: string) {
  if (typeof window !== 'undefined') {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    try {
      const item = JSON.parse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }

      return item.value;
    } catch (error) {
      console.error("Erreur lors de l'analyse de getWithExpiry:", error);
      return null;
    }
  }
}
