import ApolloClient from '@apollo/client';
import { GetLogin } from '../common/graphql/types';
import { GET_LOGIN } from '../hooks/useLogin';
import { LOCAL_STORAGE_KEY_USER_GUIDING_RANDOM_ID } from '../common/constants';

const createUserGuidingRandomUUID = (): string => {
  const sessionRandomID = window?.localStorage?.getItem(LOCAL_STORAGE_KEY_USER_GUIDING_RANDOM_ID);
  const cryptoRandomID = sessionRandomID !== null ? sessionRandomID : window?.crypto?.randomUUID();
  if (sessionRandomID === null || sessionRandomID !== cryptoRandomID) window?.localStorage?.setItem(LOCAL_STORAGE_KEY_USER_GUIDING_RANDOM_ID, cryptoRandomID);
  return cryptoRandomID;
};

const updateUserGuidingAnonymous = () => {
  const userID = createUserGuidingRandomUUID();
  window?.userGuiding?.identify?.(userID, {
    segment: 0,
    isConnected: 'false'
  });
};

export const updateUserGuidingOnLogin = (client: ApolloClient.ApolloClient<object>) => {
  client
    .watchQuery<GetLogin>({
      query: GET_LOGIN
    })
    .subscribe(({ data }) => {
      if (data) {
        const userID = createUserGuidingRandomUUID();
        const { clientContact } = data;
        window?.userGuiding?.identify?.(userID, {
          segment: clientContact.IDSegment,
          isConnected: (!!clientContact.IDClient).toString(),
          newClient: (clientContact.nbCommandes == 0 || clientContact.nbCommandes == null).toString(),
          locale: clientContact.Locale
        });
      } else updateUserGuidingAnonymous();
    });
};
