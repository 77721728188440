import React from 'react';
import { X } from 'lucide-react';
import styled from 'styled-components';

type GdprMessageProps = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

export function GdprMessage({ onClose }: GdprMessageProps) {
  return (
    <GdprContainer>
      <GdprText>
        ExaBot a été conçu pour vous permettre d'accéder rapidement aux informations dont vous avez besoin. Ce chatbot utilise l'intelligence artificielle pour
        fournir des réponses pertinentes sur les produits et les services d'Exaprint. Nous vous remercions de l'utiliser uniquement pour poser des questions
        liées à ces sujets dans le respect des règles de courtoisie et de confidentialité.
        <br /> Vous pouvez consulter notre{' '}
        <PrivacyLink
          href="https://www.exaprint.fr/imprimerie-en-ligne/politique-de-confidentialite?srsltid=AfmBOoqImLxB882x5GlKdtkX_BhkdYaQsRes-dwikDGotPgSzM4UF_Ko"
          target="_blank"
          rel="noopener noreferrer"
        >
          politique de confidentialité
        </PrivacyLink>{' '}
        pour en savoir plus sur la gestion de vos données.
      </GdprText>
      <CloseGdprButton onClick={() => onClose(false)}>
        <X size={20} />
      </CloseGdprButton>
    </GdprContainer>
  );
}

const GdprContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px 21px;
  position: relative;
  border-top: 1px solid #e5e7eb;
  flex-shrink: 0;
`;

const GdprText = styled.p`
  font-size: 12px;
  margin: 5px;
  font-size: 12px;
  font-family: 'StyreneA', sans-serif !important;
  line-height: 16px !important;
  color: #676767 !important;
`;

const CloseGdprButton = styled.button`
  position: absolute;
  top: 14px;
  right: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #676767 !important;
`;

const PrivacyLink = styled.a`
  color: #ff3d77;
  text-decoration: underline;
  cursor: pointer;
`;
