import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { FormState, TypeHandleChangeForm } from '../../utils/chatbot/types';
import { ErrorMessage } from './ChatWidget';

interface InitialFormProps {
  formState: FormState;
  errors: Record<string, string>;
  handleChangeForm: TypeHandleChangeForm;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export function InitialForm({ formState, errors, handleChangeForm, onSubmit }: InitialFormProps) {
  return (
    <FormContainer onSubmit={onSubmit}>
      <InfoText>Pour mieux vous accompagner et répondre à vos questions, merci de renseigner votre nom et votre email avant de démarrer le chat.</InfoText>

      <StyledInput name="userName" placeholder="Nom" value={formState.userName} onChange={handleChangeForm} required />
      {errors.userName && <ErrorMessage>{errors.userName}</ErrorMessage>}

      <StyledInput name="email" type="email" placeholder="Email" value={formState.email} onChange={handleChangeForm} required />
      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}

      <StyledButton type="submit" onClick={onSubmit}>
        Démarrer le chat
      </StyledButton>
    </FormContainer>
  );
}

/* ---------------------------------------------
 *   Styles propres au formulaire initial
 * --------------------------------------------- */
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #676767;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'StyreneA', sans-serif !important;
  line-height: 21px !important;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-family: 'StyreneA', sans-serif !important;
  box-sizing: border-box;
`;

const StyledButton = styled.button`
  background-color: #337c99;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  font-family: 'StyreneA', sans-serif !important;
  width: 70%;
  font-size: 16px;
  /* margin: 0 auto; */

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
