// -------------------------------------------------------------------
// File: forms/ZendeskForm.tsx
// -------------------------------------------------------------------

import React, { FormEvent, ChangeEvent } from 'react';
import styled from 'styled-components';
import { FormState, TypeHandleChangeForm } from '../../utils/chatbot/types';
import { ErrorMessage } from './ChatWidget';
import { RequestTypeKey } from '../../utils/chatbot/idFieldMapping';
import renderSecondLevel from './RenderSecondLevel';
import renderOptionalFields from './RenderOptionalFields';
import { SimpleFileUpload } from './SimpleFileUpload';

interface ZendeskFormProps {
  formState: FormState;
  errors: Record<string, string>;
  isSubmitting: boolean;
  isMobile: boolean;
  validRequestTypes: string[];
  handleChangeForm: TypeHandleChangeForm;
  handleRequestType: (evt: ChangeEvent<HTMLSelectElement>) => void;
  handleSecondRequestType: (evt: ChangeEvent<HTMLSelectElement>) => void;
  setTokenImages: React.Dispatch<React.SetStateAction<string[]>>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export function ZendeskForm({
  formState,
  errors,
  isSubmitting,
  isMobile,
  validRequestTypes,
  handleChangeForm,
  handleRequestType,
  handleSecondRequestType,
  setTokenImages,
  onSubmit
}: ZendeskFormProps) {
  return (
    <FormContainer onSubmit={onSubmit}>
      <StyledInput name="userName" placeholder="Nom" value={formState.userName} onChange={handleChangeForm} required />
      {errors.userName && <ErrorMessage>{errors.userName}</ErrorMessage>}

      <StyledInput name="email" type="email" placeholder="Email" value={formState.email} onChange={handleChangeForm} required />
      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}

      <StyledSelect name="requestType" value={formState.requestType} onChange={handleRequestType} required>
        <option value="">Objet de la demande</option>
        <option value="informations_d_ordre_générale">Obtenir des informations d'ordre générale</option>
        <option value="passer_une_nouvelle_commande_">Passer une nouvelle commande</option>
        <option value="accompagnement_devis">Accompagnement sur un devis</option>
        <option value="informations_sur_une_commande_en_cours_">Obtenir des informations sur une commande en cours</option>
        <option value="information_sur_votre_réclamation">Obtenir des informations sur une réclamation</option>
        <option value="informations_sur_mon_compte">Obtenir des informations sur votre compte</option>
        <option value="information_sur_vos_factures_vos_reglements">Obtenir une information sur vos factures / vos règlements</option>
      </StyledSelect>
      {errors.requestType && <ErrorMessage>{errors.requestType}</ErrorMessage>}

      {/* Second level de requestType */}
      {formState.requestType !== '' && (
        <StyledSelect name="secondRequestType" value={formState.secondRequestType} onChange={handleSecondRequestType} required>
          {renderSecondLevel(formState.requestType as RequestTypeKey)}
        </StyledSelect>
      )}
      {formState.requestType !== '' && errors.secondRequestType && <ErrorMessage>{errors.secondRequestType}</ErrorMessage>}

      {/* Champs supplémentaires si nécessaire */}
      {validRequestTypes.includes(formState.requestType) && (
        <>{renderOptionalFields(formState.requestType as RequestTypeKey, formState.secondRequestType as RequestTypeKey, handleChangeForm, formState, errors)}</>
      )}

      <StyledInput name="subject" placeholder="Sujet" value={formState.subject} onChange={handleChangeForm} required />
      {errors.subject && <ErrorMessage>{errors.subject}</ErrorMessage>}

      <StyledTextarea name="description" placeholder="Description" value={formState.description} onChange={handleChangeForm} maxLength={800} required />
      {errors.description && <ErrorMessage>{errors.description}</ErrorMessage>}

      <SimpleFileUpload setTokenImages={setTokenImages} isMobile={isMobile} />

      <StyledButton type="submit" disabled={isSubmitting} onClick={onSubmit}>
        {isSubmitting ? 'Envoi en cours...' : 'Envoyer ma demande'}
      </StyledButton>
    </FormContainer>
  );
}

/* ---------------------------------------------
 *   Styles propres au formulaire Zendesk
 * --------------------------------------------- */
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  font-family: 'StyreneA', sans-serif !important;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-family: 'StyreneA', sans-serif !important;
  box-sizing: border-box;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-family: 'StyreneA', sans-serif !important;
  box-sizing: border-box;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  resize: vertical;
  font-family: 'StyreneA', sans-serif !important;
  box-sizing: border-box;
`;

const StyledButton = styled.button`
  background-color: #337c99;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  font-family: 'StyreneA', sans-serif !important;
  width: 70%;
  font-size: 16px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
