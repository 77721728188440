import axios from 'axios';
import { ChatMessage, FormState } from '../types';

export async function apiZendeskCall(formState: FormState, tokenImages: string[], chatHistory: ChatMessage[], urlSubmitZendeskForm: string) {
  console.log(...[formState, '👀 [zendesk.ts:5]: formState'].reverse());
  try {
    const response = await axios.post(urlSubmitZendeskForm, {
      form_state: formState,
      token_images: tokenImages,
      chat_history: chatHistory
    });
    return response;
  } catch (error) {
    console.error("Erreur lors de l'appel à l'API:", error);
    throw error;
  }
}
