import { FormState } from './types';

export const phoneNumberRegex = /^\+?[0-9]{1,15}$/;

export function isValidEmail(email: string): boolean {
  const re = /^[A-Za-z0-9._%+\-]+@(?!.*\.\.)[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$/;
  return re.test(email);
}

export function isDigitsOnly(value: string): boolean {
  return /^\d+$/.test(value);
}

export function validateForm(formState: FormState, formType: 'ticket' | 'callback', showZendeskForm: boolean, phoneNumberRegex: RegExp) {
  const newErrors: { [key: string]: string } = {};

  if (!formState.userName.trim()) {
    newErrors.userName = 'Le nom est requis.';
  }

  if (!formState.email.trim()) {
    newErrors.email = "L'email est requis.";
  } else if (!isValidEmail(formState.email)) {
    newErrors.email = "L'email n'est pas valide.";
  }

  if (!formState.requestType.trim()) {
    newErrors.requestType = 'Veuillez sélectionner un objet pour la demande.';
  }

  if (!formState.secondRequestType.trim()) {
    newErrors.secondRequestType = 'Veuillez préciser votre demande.';
  }

  if (!formState.description.trim()) {
    newErrors.description = 'La description est requise.';
  }

  if (formType === 'ticket') {
    if (!formState.subject.trim()) {
      newErrors.subject = 'Le sujet est requis.';
    }
    if (!showZendeskForm) {
      if (!formState.phoneNumber.trim()) {
        newErrors.phoneNumber = 'Numéro de téléphone requis.';
      } else if (!phoneNumberRegex.test(formState.phoneNumber)) {
        newErrors.phoneNumber = 'Numéro de téléphone invalide. Seuls les chiffres et “+” sont autorisés.';
      }
    }
  } else {
    if (!formState.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Numéro de téléphone requis.';
    } else if (!phoneNumberRegex.test(formState.phoneNumber)) {
      newErrors.phoneNumber = 'Numéro de téléphone invalide. Seuls les chiffres et “+” sont autorisés.';
    }
  }

  if (formState.requestType.trim() === 'informations_sur_mon_compte') {
    if (!formState.account_number || typeof formState.account_number !== 'string') {
      newErrors.account_number = 'Le numéro de compte est requis.';
    } else if (!formState.account_number.trim()) {
      newErrors.account_number = 'Le numéro de compte est requis.';
    }
  }

  if (formState.secondRequestType.trim() === 'demande_de_multipoint_point_de_livraison_sur_produit_gamme_catalogue__n°_de_devis_obligatoire_') {
    if (!formState.quote_number || !isDigitsOnly(formState.quote_number.toString())) {
      newErrors.quote_number = 'Renseignez uniquement des chiffres.';
    }
    if (!formState.delivery_points_number || !isDigitsOnly(formState.delivery_points_number.toString())) {
      newErrors.delivery_points_number = 'Renseignez uniquement des chiffres.';
    }
  }

  if (formState.requestType.trim() === 'informations_sur_une_commande_en_cours_' || formState.requestType.trim() === 'information_sur_votre_réclamation') {
    if (!formState.order_number || !isDigitsOnly(formState.order_number.toString())) {
      newErrors.order_number = 'Renseignez uniquement des chiffres.';
    }
  }

  console.error('Error', newErrors, Object.keys(newErrors).length === 0);
  return {
    newErrors,
    isValid: Object.keys(newErrors).length === 0
  };
}
