import styled from 'styled-components';
import React from 'react';
import { ChevronDown, X, ChevronLeft } from 'lucide-react';
import aiIcon from '../../../assets/img/ExaTest.svg';

type ChatHeaderProps = {
  isMobile: boolean;
  isAdvisorTab: boolean;
  isZendeskForm: boolean;
  isCallbackForm: boolean;
  onClickAdvisorTab: () => void;
  onClickBack: () => void;
  onClickClose: () => void;
};

export function ChatHeader(props: ChatHeaderProps) {
  const { isMobile, isAdvisorTab, isZendeskForm, isCallbackForm, onClickAdvisorTab, onClickBack, onClickClose } = props;

  return (
    <ChatHeaderContainer>
      {isAdvisorTab || isZendeskForm || isCallbackForm ? (
        <>
          <BackButton onClick={onClickBack}>
            <ChevronLeft size={24} />
          </BackButton>
          <ChatTitle>{isCallbackForm ? 'Être rappelé par un agent' : isZendeskForm ? 'Envoyer votre demande' : "Joindre l'assistance"}</ChatTitle>
          <CloseButton onClick={onClickClose}>{isMobile ? <X size={24} /> : <ChevronDown size={24} />}</CloseButton>
        </>
      ) : (
        <>
          <HeaderLeft>
            <AvatarContainer>
              <StyledIcon src={aiIcon} alt="ExaBot" />
              <OnlineBadge />
            </AvatarContainer>
            <ChatTitle>ExaBot</ChatTitle>
          </HeaderLeft>
          <AdvisorButton onClick={onClickAdvisorTab}>Joindre l'assistance</AdvisorButton>
          <CloseButton onClick={onClickClose}>{isMobile ? <X size={24} /> : <ChevronDown size={24} />}</CloseButton>
        </>
      )}
    </ChatHeaderContainer>
  );
}
const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 999px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const ChatTitle = styled.h1`
  font-size: 14px;
  line-height: 21px;
  font-weight: normal !important;
  font-family: 'StyreneA', sans-serif !important;
  text-align: center;
  flex-grow: 1;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  border-radius: 999px;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const AvatarContainer = styled.div`
  position: relative;
  display: flex;
`;
const StyledIcon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e5e7eb;
`;

const OnlineBadge = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #4caf50;
  border: 2px solid #ffffff;
  border-radius: 50%;
`;
const ChatHeaderContainer = styled.header`
  background-color: #337c99;
  color: white;
  padding: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;

  @media (max-width: 600px) {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
`;

const AdvisorButton = styled.button`
  background: none;
  border: 1px solid white;
  color: white;
  font-size: 14px;
  font-weight: normal !important;
  font-family: 'StyreneA', sans-serif !important;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 999px;
  white-space: nowrap;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;
