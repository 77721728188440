import React, { FormEvent } from 'react';
import { ArrowUp } from 'lucide-react';
import styled from 'styled-components';

type ChatFooterProps = {
  inputValue: string;
  isLoading: boolean;
  onChangeInput: (val: string) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  showGdprMessage: boolean;
  setShowGdprMessage: React.Dispatch<React.SetStateAction<boolean>>;
  hasFilledInitialForm: boolean;
};

export function ChatFooter({ inputValue, isLoading, onChangeInput, onSubmit, showGdprMessage, setShowGdprMessage, hasFilledInitialForm }: ChatFooterProps) {
  return (
    <ChatFooterContainer>
      <ChatForm onSubmit={onSubmit}>
        <ChatInput
          placeholder="Votre message..."
          value={inputValue}
          onChange={(e: { target: { value: string } }) => {
            onChangeInput(e.target.value);
            if (showGdprMessage) {
              setShowGdprMessage(false);
            }
          }}
          disabled={!hasFilledInitialForm || isLoading}
        />
        <SendButton type="submit" disabled={!inputValue.trim()}>
          <ArrowUp size={24} />
        </SendButton>
      </ChatForm>
    </ChatFooterContainer>
  );
}

const ChatFooterContainer = styled.div`
  padding: 8px;
  border-top: 1px solid #e5e7eb;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: sticky;
  bottom: 0;
`;

const ChatForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ChatInput = styled.input<{ isMobile: boolean }>`
  flex-grow: 1;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: 'StyreneA', sans-serif !important;
  //word-wrap: break-word;
`;

const SendButton = styled.button<{ disabled: boolean }>`
  background-color: ${props => (props.disabled ? 'transparent' : '#337C99')};
  color: ${props => (props.disabled ? 'grey' : 'white')};
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
